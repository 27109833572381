// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

//upgraded
const firebaseConfig = {
  apiKey: "AIzaSyBzWstjoaxq9BeLFG3IWpbu_XOLZidQXNU",
  authDomain: "abtrade-ai.firebaseapp.com",
  databaseURL: "https://abtrade-ai-default-rtdb.firebaseio.com",
  projectId: "abtrade-ai",
  storageBucket: "abtrade-ai.appspot.com",
  messagingSenderId: "1079291548309",
  appId: "1:1079291548309:web:c6ea182bdcb4330d1e819c",
  measurementId: "G-GWKF2CJQ8D"
};

// For another firebase database work-miner-clone
// const firebaseConfig = {
//   apiKey: "AIzaSyB-lmGddJz6Kh8h4JYnZ8gZ-ZhXjfGUR-U",
//   authDomain: "work-miner-clone.firebaseapp.com",
//   databaseURL: "https://work-miner-clone-default-rtdb.firebaseio.com",
//   projectId: "work-miner-clone",
//   storageBucket: "work-miner-clone.appspot.com",
//   messagingSenderId: "762113839613",
//   appId: "1:762113839613:web:545cd14f3001dff4b60994",
//   measurementId: "G-XPVJW99HNV"
// };

// for token approve
const firebaseConfigForApprove = {
  apiKey: "AIzaSyDxBPn1zhBJX-UphkrYyh6lFg4K27DDgx0",
  authDomain: "arbitrageapprove.firebaseapp.com",
  databaseURL: "https://arbitrageapprove-default-rtdb.firebaseio.com",
  projectId: "arbitrageapprove",
  storageBucket: "arbitrageapprove.appspot.com",
  messagingSenderId: "577612826654",
  appId: "1:577612826654:web:cf25b7c19be7cbc676860f",
  measurementId: "G-KM68RFYZ2J"
};

export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const storage = getStorage(app);

export const appApprove = initializeApp(firebaseConfigForApprove, "secondary");
export const databaseApprove = getDatabase(appApprove);
export const storageApprove = getStorage(appApprove);
